@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --main-darklg: #1d2635;
  --main-dark: #161d29;
  --primary-color: #2f80ec;
  --main-light: #eeeeee;
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

.fullPageImportant {
  width: 100%important;
  height: 100vh!important;
  padding: 0px!important;
  /*max-height: inherit!important;*/
  /*max-width: inherit!important;*/
  border-radius: 0px!important;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  position: relative;
  width: 100%;
  background-color: var(--main-darklg);
}

.logo > h3 {
  color: var(--main-light);
}

.main {
  overflow: hidden;
  height: 92vh;
  display: flex;
}

.main__left {
  flex: 0.7;
  display: flex;
  flex-direction: column;
}

.videos__group {
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* background-color: var(--main-dark); */
}

video {
  object-fit: cover;
  /*height: 300px;*/
  /*height: 180px;*/
  /*border-radius: 1rem;*/
  /*margin: 0.5rem;*/
  /*width: 400px;*/
  /*width: 240px;*/
  /* transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg); */
}

.options {
  padding: 1rem;
  display: flex;
  background-color: var(--main-darklg);
}

.options__left {
  display: flex;
}

.options__right {
  margin-left: auto;
}

.options__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  height: 50px;
  border-radius: 5px;
  color: var(--main-light);
  font-size: 1.2rem;
  width: 50px;
  margin: 0 0.5rem;
}

.background__red {
  background-color: #f6484a!important;
}

.main__right {
  display: flex;
  flex-direction: column;
  flex: 0.3;
  background-color: #242f41;
}

.main__chat_window {
  flex-grow: 1;
  overflow-y: scroll;
}

.main__chat_window::-webkit-scrollbar {
  display: none;
}

.main__message_container {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main__message_container > input {
  height: 50px;
  flex: 1;
  font-size: 1rem;
  border-radius: 5px;
  padding-left: 20px;
  border: none;
}

.messages {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
}

.message {
  display: flex;
  flex-direction: column;
}

.message > b {
  color: #eeeeee;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.message > b > i {
  margin-right: 0.7rem;
  font-size: 1.5rem;
}

.message > span {
  background-color: #eeeeee;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 5px;
}

#video-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
/*
#showChat {
  display: none;
}
*/
.header__back {
  display: none;
  position: absolute;
  font-size: 1.3rem;
  top: 17px;
  left: 28px;
  color: #fff;
}

@media (max-width: 700px) {
  .main__right {
    display: none;
  }
  .main__left {
    width: 100%;
    flex: 1;
  }

  video {
    height: auto;
    width: 100%;
  }

  #showChat {
    display: flex;
  }
}
