@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fcfcfc inset !important;
  -webkit-text-fill-color: #000 !important;
}

div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
body {
  font-weight: 300;
  overflow-x: hidden;
  height: 100%;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
b {
  font-weight: 800;
}
a {
  text-decoration: none !important;
}
h1 {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}
h2 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
h3 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
h4 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
h5 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
h6 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
p {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0px !important;
}
select {
  /* font-size:; */
  outline: none !important;
  width: 340px !important;
}
input {
  outline: none !important;
}
label {
  font-size: 16px;
}
button {
  outline: none !important;
}
textarea {
  outline: none !important;
  resize: none !important;
  width: 340px !important;
  overflow: auto !important;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 500;
  line-height: 1.2;
}
/*TEXT SIZES FROM ZEPLIN START*/

.H1medium {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 1px;
}
.H1regular {
  font-family: Poppins;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 1px;
}
.H2bold {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.5px;
}
.H2bold {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.5px;
}
.H2medium {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 1px;
}
.H2regular {
  font-family: Poppins;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 1px;
}
.H3bold {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.75px;
}
.H3medium {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.75px;
}
.H3regular {
  font-family: Poppins;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.75px;
}
.P1bold {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.75px;
}
.P1medium {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.75px;
}
.P1regular {
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.75px;
}
.Pmedium {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
}
.Pbold {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
}
.Pregular {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.75px;
}
.Small-Labelnormal {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.25px;
}
.Small-LabelCaps {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.Small-Labelregular {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.25px;
}
.Small-Label-cards {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
}
.Small-Label-11 {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.91;
  letter-spacing: 0.25px;
}
.Smallest-labelsmall-label {
  font-family: Poppins;
  font-size: 9.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: 0.25px;
}
.italic {
  font-style: italic !important;
}

/*TEXT SIZES FROM ZEPLIN END*/

/*COLORS CSS*/

.backColPink {
  background-color: #bf0058;
}

.colLinksAndTitlesBlack {
  color: #262430 !important;
}
.backColLinksAndTitlesBlack {
  background-color: #262430 !important;
}
.colLabelGray {
  color: #737798 !important;
}
.backColLabelGray {
  background-color: #737798 !important;
}
.colPrimaryGreen {
  color: #3e8b9c !important;
}
.backColPrimaryGreen {
  background-color: #3e8b9c !important;
}
.colSecondaryGreen {
  color: #155c6b !important;
}
.backColWhite {
  background-color: #fff !important;
}
.backColSecondaryGreen {
  background-color: #155c6b !important;
}
.backColSecondaryGreenOpacity {
  background-color: rgba(21, 92, 107, 0.45) !important;
}
.backColGrayOpacity {
  background-color: rgba(61, 61, 61, 0.4) !important;
}
.backColGrayOpacity2 {
  background-color: rgba(61, 61, 61, 0.5) !important;
}
.colElementBackgroundGray {
  color: #f7f7f7 !important;
}
.backColElementBackgroundGray {
  background-color: #f7f7f7 !important;
}
.colPlaceHolderGray {
  color: #a0a3bd !important;
}
.backColPlaceHolderGray {
  background-color: #a0a3bd !important;
}
.colOffWhite {
  color: #fcfcfc !important;
}
.backColOffWhite {
  /*background-color:#fcfcfc!important;*/
  background-color: #fafafa !important;
}
.colBorderLinesGray {
  color: #d9dbe9 !important;
}
.backColBorderLinesGray {
  background-color: #d9dbe9 !important;
}
.colErrorRed {
  color: #c30052 !important;
}
.backColErrorRed {
  background-color: #c30052 !important;
}
.colSuccessLightGreen {
  color: #f2fffb !important;
}
.backColSuccessLightGreen {
  background-color: #f2fffb !important;
}
.colSuccessGreen {
  color: #00968d !important;
}
.backColSuccessGreen {
  background-color: #00968d !important;
}
.colErrorLightRed {
  color: #fff3f8 !important;
}
.backColErrorLightRed {
  color: #fff3f8 !important;
}
.colCardsGray {
  color: #f6f6f6 !important;
}
.backColCardsGray {
  background-color: #f6f6f6 !important;
}
.colPrimaryLight {
  color: rgba(62, 139, 156, 0.13) !important;
}
.backColPrimaryLight {
  background-color: rgba(62, 139, 156, 0.13) !important;
}
.backgroundGradientForButtons {
  background-image: linear-gradient(
    to right top,
    #3e8b9c,
    #347f8f,
    #2a7383,
    #206777,
    #155c6b
  ) !important;
}
.textLinearGradieant {
  background: -webkit-linear-gradient(left, #3e8b9c, #155c6b) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.backColInput {
  background-color: rgba(239, 240, 246, 0.67) !important;
}
.backColTransparent {
  background-color: transparent !important;
}
.colTwitterBlue {
  color: #1da1f2 !important;
}
.backColTwitterBlue {
  background-color: #1da1f2 !important;
}
.colGoogleRed {
  color: #db4437 !important;
}
.backColRed {
  background-color: #d40404 !important;
}
.backColGoogleRed {
  background-color: #db4437 !important;
}
.colFacebookBlue {
  color: #4267b2 !important;
}
.backColFacebookBlue {
  background-color: #4267b2 !important;
}
.colDarkFray {
  color: #575757 !important;
}
.backColDarkFray {
  background-color: #575757 !important;
}
.backColGreen {
  background-color: #39bb18 !important;
}
/*COLORS CSS END*/

/*WIDTHS AND HEIGHT CSS START*/

.h100 {
  height: 100% !important;
}
.h70 {
  height: 70% !important;
}
.wAuto {
  width: auto !important;
}
.w10 {
  width: 10% !important;
}
.w20 {
  width: 20% !important;
}
.w24 {
  width: 24% !important;
}
.w25 {
  width: 25% !important;
}
.w30 {
  width: 30% !important;
}
.w38 {
  width: 38% !important;
}
.w40 {
  width: 40% !important;
}
.w50 {
  width: 50% !important;
}
.w60 {
  width: 60% !important;
}
.w70 {
  width: 70% !important;
}
.w75 {
  width: 75% !important;
}
.w80 {
  width: 80% !important;
}
.w90 {
  width: 90% !important;
}
.w100 {
  width: 100% !important;
}
.w104 {
  width: 104px !important;
}
.w90px {
  width: 90px !important;
}
.w120 {
  width: 120px !important;
}
.w140 {
  width: 140px !important;
}
.w180 {
  width: 180px !important;
}
.w240 {
  width: 240px !important;
}
.w300 {
  width: 300px !important;
}
.w340 {
  width: 340px !important;
}
.w380 {
  width: 380px !important;
}
.w400 {
  width: 400px !important;
}
.w440 {
  width: 440px !important;
}
.w510 {
  width: 510px !important;
}
.w540 {
  width: 540px !important;
}
.w600 {
  width: 600px !important;
}
.w820 {
  width: 820px;
}
.w900 {
  width: 900px;
}
.minW110 {
  min-width: 110px !important;
}

/*WIDTHS CSS END*/

/*MARGINS PADDINGS END ALIGNMENT CSS START*/

.mXAuto {
  margin: 0 auto !important;
}
.mLeftAuto {
  margin-left: auto !important;
}
.mRightAuto {
  margin-right: auto !important;
}
.mRight0 {
  margin-right: 0px !important;
}
.mRight1 {
  margin-right: 1px !important;
}
.mRight2 {
  margin-right: 2px !important;
}
.mRight4 {
  margin-right: 4px !important;
}
.mRight5 {
  margin-right: 5px !important;
}
.mRight8 {
  margin-right: 8px !important;
}
.mRight10 {
  margin-right: 10px !important;
}
.mRight12 {
  margin-right: 12px !important;
}
.mRight15 {
  margin-right: 15px !important;
}
.mRight16 {
  margin-right: 16px !important;
}
.mRight20 {
  margin-right: 20px !important;
}
.mRight20LargeVP {
  margin-right: 20px !important;
}
.mRight24 {
  margin-right: 24px !important;
}
.mRight30 {
  margin-right: 30px !important;
}
.mRight40 {
  margin-right: 40px !important;
}
.mRight50 {
  margin-right: 50px !important;
}
.mRight100 {
  margin-right: 100px !important;
}
.mRight160 {
  margin-right: 160px !important;
}
.mLeft0 {
  margin-left: 0px !important;
}
.mLeft1 {
  margin-left: 1px !important;
}
.mLeft2 {
  margin-left: 2px !important;
}
.mLeft4 {
  margin-left: 4px !important;
}
.mLeft5 {
  margin-left: 5px !important;
}
.mLeft8 {
  margin-left: 8px !important;
}
.mLeft10 {
  margin-left: 10px !important;
}
.mLeft12 {
  margin-left: 12px !important;
}
.mLeft15 {
  margin-left: 15px !important;
}
.mLeft16 {
  margin-left: 16px !important;
}
.mLeft20 {
  margin-left: 20px !important;
}
.mLeft24 {
  margin-left: 24px !important;
}
.mLeft30 {
  margin-left: 30px !important;
}
.mLeft40 {
  margin-left: 40px !important;
}
.mLeft50 {
  margin-left: 50px !important;
}
.mLeft100 {
  margin-left: 100px !important;
}
.mTop1 {
  margin-top: 1px !important;
}
.mTop2 {
  margin-top: 2px !important;
}
.mTop4 {
  margin-top: 4px !important;
}
.mTop5 {
  margin-top: 5px !important;
}
.mTop8 {
  margin-top: 8px !important;
}
.mTop10 {
  margin-top: 10px !important;
}
.mTop12 {
  margin-top: 12px !important;
}
.mTop15 {
  margin-top: 15px !important;
}
.mTop16 {
  margin-top: 16px !important;
}
.mTop20 {
  margin-top: 20px !important;
}
.mTop24 {
  margin-top: 24px !important;
}
.mTop30 {
  margin-top: 30px !important;
}
.mTop40 {
  margin-top: 40px !important;
}
.mTop50 {
  margin-top: 50px !important;
}
.mTop70 {
  margin-top: 70px !important;
}
.mTop200 {
  margin-top: 200px !important;
}
.mBottom1 {
  margin-bottom: 1px !important;
}
.mBottom2 {
  margin-bottom: 2px !important;
}
.mBottom4 {
  margin-bottom: 4px !important;
}
.mBottom5 {
  margin-bottom: 5px !important;
}
.mBottom8 {
  margin-bottom: 8px !important;
}
.mBottom10 {
  margin-bottom: 10px !important;
}
.mBottom12 {
  margin-bottom: 12px !important;
}
.mBottom15 {
  margin-bottom: 15px !important;
}
.mBottom16 {
  margin-bottom: 16px !important;
}
.mBottom20 {
  margin-bottom: 20px !important;
}
.mBottom24 {
  margin-bottom: 24px !important;
}
.mBottom30 {
  margin-bottom: 30px !important;
}
.mBottom40 {
  margin-bottom: 40px !important;
}
.mBottom50 {
  margin-bottom: 50px !important;
}
.mBottom60 {
  margin-bottom: 60px !important;
}
.mBottom70 {
  margin-bottom: 70px !important;
}
.mY4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.mY8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mY14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}
.mY16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.mY20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mY30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.mY50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.mY70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.mX4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}
.mX10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.mX16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}
.mX20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.mX50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}
.p0 {
  padding: 0px !important;
}
.pRight1 {
  padding-right: 1px !important;
}
.pRight2 {
  padding-right: 2px !important;
}
.pRight4 {
  padding-right: 4px !important;
}
.pRight5 {
  padding-right: 5px !important;
}
.pRight8 {
  padding-right: 8px !important;
}
.pRight10 {
  padding-right: 10px !important;
}
.pRight12 {
  padding-right: 12px !important;
}
.pRight15 {
  padding-right: 15px !important;
}
.pRight16 {
  padding-right: 16px !important;
}
.pRight20 {
  padding-right: 20px !important;
}
.pRight24 {
  padding-right: 24px !important;
}
.pRight30 {
  padding-right: 30px !important;
}
.pRight40 {
  padding-right: 40px !important;
}
.pRight50 {
  padding-right: 50px !important;
}
.pRight60 {
  padding-right: 60px !important;
}
.pRight70 {
  padding-right: 70px !important;
}
.pRight80 {
  padding-right: 70px !important;
}
.pRight90 {
  padding-right: 70px !important;
}
.pLeft0 {
  padding-left: 0px !important;
}
.pLeft1 {
  padding-left: 1px !important;
}
.pLeft2 {
  padding-left: 2px !important;
}
.pLeft4 {
  padding-left: 4px !important;
}
.pLeft5 {
  padding-left: 5px !important;
}
.pLeft6 {
  padding-left: 6px !important;
}
.pLeft8 {
  padding-left: 8px !important;
}
.pLeft10 {
  padding-left: 10px !important;
}
.pLeft12 {
  padding-left: 12px !important;
}
.pLeft15 {
  padding-left: 15px !important;
}
.pLeft16 {
  padding-left: 16px !important;
}
.pLeft20 {
  padding-left: 20px !important;
}
.pLeft24 {
  padding-left: 24px !important;
}
.pLeft30 {
  padding-left: 30px !important;
}
.pLeft40 {
  padding-left: 40px !important;
}
.pLeft50 {
  padding-left: 50px !important;
}
.pLeft60 {
  padding-left: 60px !important;
}
.pLeft70 {
  padding-left: 70px !important;
}
.pLeft80 {
  padding-left: 80px !important;
}
.pLeft90 {
  padding-left: 90px !important;
}
.pTop1 {
  padding-top: 1px !important;
}
.pTop2 {
  padding-top: 2px !important;
}
.pTop4 {
  padding-top: 4px !important;
}
.pTop5 {
  padding-top: 5px !important;
}
.pTop8 {
  padding-top: 8px !important;
}
.pTop10 {
  padding-top: 10px !important;
}
.pTop12 {
  padding-top: 12px !important;
}
.pTop15 {
  padding-top: 15px !important;
}
.pTop16 {
  padding-top: 16px !important;
}
.pTop20 {
  padding-top: 20px !important;
}
.pTop24 {
  padding-top: 24px !important;
}
.pTop30 {
  padding-top: 30px !important;
}
.pTop40 {
  padding-top: 40px !important;
}
.pTop50 {
  padding-top: 50px !important;
}
.pBottom1 {
  padding-bottom: 1px !important;
}
.pBottom2 {
  padding-bottom: 2px !important;
}
.pBottom4 {
  padding-bottom: 4px !important;
}
.pBottom5 {
  padding-bottom: 5px !important;
}
.pBottom8 {
  padding-bottom: 8px !important;
}
.pBottom10 {
  padding-bottom: 10px !important;
}
.pBottom12 {
  padding-bottom: 12px !important;
}
.pBottom15 {
  padding-bottom: 15px !important;
}
.pBottom16 {
  padding-bottom: 16px !important;
}
.pBottom20 {
  padding-bottom: 20px !important;
}
.pBottom24 {
  padding-bottom: 24px !important;
}
.pBottom30 {
  padding-bottom: 30px !important;
}
.pBottom40 {
  padding-bottom: 40px !important;
}
.pBottom50 {
  padding-bottom: 50px !important;
}
.pBottom70 {
  padding-bottom: 70px !important;
}
.pX0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.pY4 {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
.pY10 {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
.pY14 {
  padding-bottom: 14px !important;
  padding-top: 14px !important;
}
.pX14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.pY20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}
.pY30 {
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}
.pY50 {
  padding-bottom: 50px !important;
  padding-top: 50px !important;
}
.pY {
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}
.pY6 {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
}
.pX4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.pX6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.pX10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.pX20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.pX30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.pX80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.textAlignLeft {
  text-align: left !important;
}
.textAlignRight {
  text-align: right !important;
}
.textAlignCenter {
  text-align: center !important;
}

/*MARGINS PADDINGS END ALIGNMENT CSS END*/

/*BORDER CSS CLASSES START*/

.borderNone {
  border: none !important;
}
.borderRadius4 {
  border-radius: 4px;
  overflow: hidden;
}
.borderRadius12 {
  border-radius: 12px;
  overflow: hidden;
}
.borderRadius18 {
  border-radius: 18px;
  overflow: hidden;
}
.borderRadius24 {
  border-radius: 24px;
  overflow: hidden;
}
.borderRadius24Top {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  overflow: hidden;
}
.borderRadius12Top {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  overflow: hidden;
}
.borderRadiusForLandingPageImg {
  border-top-left-radius: 110px;
  border-bottom-left-radius: 110px;
  border-top-right-radius: 170px;
  overflow: hidden;
}
.borderRadiusForChatOutputLeft {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.borderRadiusSpanSelect {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.borderRadiusForChatOutputRight {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.borderRadius50 {
  border-radius: 50% !important;
  overflow: hidden;
}
.borderRadiusForSocialMediaButtons {
  border-radius: 30px;
}
.borderDashed {
  border: 2px dashed #3e8b9c;
}
.borderGrayInput {
  border: 2px solid #eff0f6;
}
.borderGrayTopInput {
  border-top: 2px solid #eff0f6;
}
.borderBottomForFooterForm {
  border-bottom: 1px solid #4d4d4d !important;
}
.borderBottomForButtons {
  border-bottom: 2px solid #3e8b9c !important;
}
.borderBottomRedForButtons {
  border-bottom: 2px solid #c30052 !important;
}
.borderBottomForButtonsGray {
  border-bottom: 2px solid #eff0f6 !important;
}
.borderBottomForButtonsGray2 {
  border-bottom: 1px solid #eff0f6 !important;
}
.borderBottomInputGray {
  border-bottom: 2px solid #d1d1d1 !important;
}
.borderSecundaryGreen {
  border: 1px solid #155c6b;
}
.borderPrimaryGreen {
  border: 2px solid #3e8b9c;
}
.dashedBorder2 {
  border: 2px dashed #3e8b9c !important;
  border-radius: 10px;
}

/*BORDER CSS CLASSES END*/

/*GENERAL CSS CLASSES*/

.viewsWrapper {
  min-height: calc(100vh - 94px - 84px);
}
.whitelabelSmallSeparator {
  width: 60%;
  margin: 10px auto;
  border-top: 3px solid #155c6b;
}
.verticallyAlignMid {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}
.verticallyAlignMidJustConStart {
  display: flex !important;
  align-items: center !important;
  /*justify-content: start;*/
  justify-content: flex-start !important;
  text-align: center !important;
}
.verticallyAlignMidJustConStartForText {
  display: flex !important;
  align-items: center !important;
  /*justify-content: start;*/
  justify-content: flex-start !important;
  text-align: left !important;
}
.verticallyAlignMidJustConEnd {
  display: flex !important;
  align-items: center !important;
  /*justify-content: end;*/
  justify-content: flex-end !important;
  text-align: center !important;
}
.verticallyAlignMidStretch {
  display: flex !important;
  align-items: stretch !important;
  justify-content: flex-end !important;
  text-align: center !important;
}
.verticallyAlignMidStretchStart {
  display: flex !important;
  align-items: stretch !important;
  justify-content: flex-start !important;
  text-align: center !important;
}
.verticallyAlignMidStretchEnd {
  display: flex !important;
  align-items: stretch !important;
  justify-content: flex-end !important;
  text-align: center !important;
}
.verticallyAlignMidSpace {
  display: flex !important;
  /*justify-content: end;*/
  justify-content: space-evenly !important;
  text-align: center !important;
}
.verticallyAlignMidSpaceBetween {
  display: flex !important;
  /*justify-content: end;*/
  justify-content: space-between !important;
  text-align: center !important;
}
.disFlex {
  display: flex !important;
}
.textareaForStartForms {
  outline: none !important;
  resize: none !important;
  overflow: auto !important;
  height: 120px;
  max-height: 120px;
}
.textareaForStartFormsDiv {
  width: 600px !important;
}
.selectForStartFormsDiv {
  width: 600px !important;
}
.flexWrap {
  flex-wrap: wrap;
}
.cardForSelectCategories {
  width: 180px !important;
  height: 180px !important;
}
.cardForSelectCategoriesImg {
  width: 70px !important;
  height: 70px !important;
}
.profileSettingsPanelImg {
  width: 90px !important;
  height: 90px !important;
}
.welcomeImg {
  width: 500px !important;
  height: 500px !important;
}
.ratingImage {
  width: 84px !important;
  height: 84px !important;
}
.dotForListItem {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
}
.overFlowXHidden {
  overflow-x: hidden;
  overflow-y: hidden;
}
.smallSelectCss {
  width: 240px !important;
}
.sendOfferSellect {
  width: 170px !important;
}
.cursorPointer {
  cursor: pointer;
}
.widthAndHightForLandingPageImg {
  width: 100% !important;
}
.widthAndHightForLandingPageImgMain {
  width: 510px !important;
}
.outlineNone {
  outline: none !important;
}
.searchButtonOjectFit {
  object-fit: cover;
}
.boxShadowForSearchLanding {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(89, 89, 89, 0.09);
  box-shadow: 0px 3px 5px 0px rgba(89, 89, 89, 0.09);
}
.boxShadowForSettingPanelCards {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(64, 64, 64, 0.18);
  box-shadow: 0px 12px 12px 10px rgba(64, 64, 64, 0.18);
}
.posRel {
  position: relative;
}
.posAbs {
  position: absolute;
}
.recommendedMentors {
  width: 320px;
  height: 340px;
}
.landingCardsMainBio {
  width: 320px;
}
.nameRecommendedMentor {
  width: 240px;
}
.onlineOffline {
  left: 12px;
  top: 12px;
}
.likeDislike {
  top: 12px;
  right: 12px;
}
.professionExpert {
  left: 12px;
  bottom: 12px;
}
.breakWord {
  /* word-break: break-all; */
  word-break: break-word;
}
.logoNavBarImage {
  width: 60px;
  height: 60px;
}
.mainNavBarOriginalImg {
  width: 30px;
  height: 30px;
}
.reviewsCardImg {
  width: 40px;
  height: 40px;
}
.reviewsCardImgMainChat {
  width: 40px !important;
  height: 40px !important;
}
.messageImg {
  width: 46px;
  height: 46px;
}
.reviewsCardImg2 {
  width: 60px;
  height: 60px;
}
.upcomingPastCancelledNavCss {
  width: 376px;
}
.forCloseButton {
  width: 40px;
  height: 40px;
}
.scheduleSearchForm::placeholder {
  color: #3e8b9c;
}
.formForFooterEmail::placeholder {
  color: #fcfcfc;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}
.formForFooterEmail input {
  width: 272px;
}
.earningsTotNet {
  width: 290px;
}
.earningsHoursClientsSessions {
  width: 180px;
}
.redDotForNotification {
  width: 10px;
  height: 10px;
  right: 0px;
  top: 6px;
}
.redDotForMessages {
  width: 10px;
  height: 10px;
  right: 0px;
  top: 6px;
}
.dropDownForUserIcon {
  width: 160px;
  top: 40px;
  right: -20px;
  z-index: 9999;
}
.backButton {
  width: 52px;
  height: 52px;
  right: 20px;
  bottom: -26px;
}
.backButtonForMobileMessages {
  width: 40px;
  height: 40px;
}
.VideoCallImageElement {
  width: 100%;
  height: auto;
}
.VideoCallTextElement {
  display: none !important;
  width: 30%;
}
.buttonsForVideoCall {
  width: 440px;
  bottom: 30px;
  left: 100px;
}
.buttonsForAudioCallOnly {
  left: calc((100vw - 440px) / 2);
}
.buttonsForVideoCallSubb {
  width: 360px;
}
.buttonForVideoCall {
  width: 70px;
  height: 70px;
}
.onOffSwitchInput[type="checkbox"] {
  width: 32px;
  height: 18px;
  -webkit-appearance: none;
  background: #36e047;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.onOffSwitchInput:checked[type="checkbox"] {
  background: #d6d6d6;
}
.onOffSwitchInput[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  top: 1px;
  left: 2px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff;
  /*	transform: scale(1.1);*/
  transition: 0.5s;
  cursor: pointer;
}
.onOffSwitchInput:checked[type="checkbox"]:before {
  left: 14px;
}

.onOffSwitchInputOnFillters[type="checkbox"] {
  width: 50px;
  height: 28px;
  -webkit-appearance: none;
  background: #3e8b9c;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.onOffSwitchInputOnFillters:checked[type="checkbox"] {
  background: #d6d6d6;
}
.onOffSwitchInputOnFillters[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  top: 1px;
  left: 2px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff;
  /*  transform: scale(1.1);*/
  transition: 0.5s;
  cursor: pointer;
}
.onOffSwitchInputOnFillters:checked[type="checkbox"]:before {
  left: 22px;
}
.labelForRadio {
  display: inline-flex;
  align-items: center;
}
.inputForRadioLabel {
  display: none;
}
.divForRadio {
  width: 28px;
  height: 28px;
  border: 2px solid #a0a3bd;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 1px;
}
.divForRadio::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #155c6b;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.15s;
}
.inputForRadioLabel:checked + .divForRadio::after {
  transform: scale(1);
}

.filterLabelCheckBox {
  display: inline-flex;
  align-items: center;
}
.filterInputLabelCheckBox {
  display: none;
}
.divForCheckBox {
  width: 28px;
  height: 28px;
  border: 2px solid #155c6b;
  background-color: #fcfcfc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.divForCheckBox::after {
  content: "\2714";
  color: #fff;
}
.filterInputLabelCheckBox:checked + .divForCheckBox {
  background-color: #155c6b;
}
.forRecommendedMentors {
  overflow-x: scroll;
}
.calendarIconHover {
  width: 40px !important;
  height: 40px !important;
}
.ClientSettingsPanelPersonalInfoAll p {
  text-align: left;
  margin-bottom: 10px !important;
  width: 98% !important;
  word-break: break-all;
}
.HowMentorXWorksAllText {
  width: 40%;
}
.HowMentorXWorksImgMain {
  width: 500px;
  height: 500px;
}

/*css for switch input type checkbox*/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  background-color: #3e8b9c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #d9dbe9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d9dbe9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.ReschedulePopUpSubb {
  width: 800px;
}
.applyFiltersPopUpSubb {
  width: 600px;
}

.mainForRadioButtons {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.mainForRadioButtons input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.mainForRadioButtons:hover input ~ .checkmark {
  background-color: #d9d9d9;
}

/* When the radio button is checked, add a blue background */
.mainForRadioButtons input:checked ~ .checkmark {
  background-color: #155c6b;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.mainForRadioButtons input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.mainForRadioButtons .checkmark:after {
  top: 6px;
  left: 6px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #e6e6e6;
}
.spanForArrow {
  top: 0;
  right: 0;
  height: 100%;
  display: block;
  pointer-events: none;
}
.appearanceNone {
  appearance: none;
}
/* Customize the label (the checkBoxContainer) */
.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkCheckBox {
  position: absolute;
  top: 4;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input ~ .checkmarkCheckBox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmarkCheckBox {
  background-image: linear-gradient(
    to right top,
    #3e8b9c,
    #347f8f,
    #2a7383,
    #206777,
    #155c6b
  ) !important;
}

/* Create the checkmarkCheckBox/indicator (hidden when not checked) */
.checkmarkCheckBox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmarkCheckBox when checked */
.checkBoxContainer input:checked ~ .checkmarkCheckBox:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmarkCheckBox:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.loginStartingPagesImg2 {
  display: none;
}
.heightForHeadMessages {
  height: 50px;
}
.bothConversationsChat {
  height: 100%;
  overflow-y: auto;
}
.bothConversationsChatSubb {
  height: 655px;
  overflow-y: scroll;
  /* display: flex;
	flex-direction: column-reverse; */
}
.messagesMainSubb2LeftAndRight {
  width: 94% !important;
  margin-left: auto;
}
.messagesLeftMain {
  height: 100%;
  width: 90% !important;
}
.messagesLeftSubb {
  width: auto !important;
  display: inline-block !important;
}
.addNewNoteInSessionButtonMain {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  /*border-top-right-radius: 24px;*/
  /*border-top-left-radius: 24px;*/
}
.messagesMainSubb2SubbForNotes {
  height: calc(100% - 114px);
}
.messagesMainSubb2SubbForEditNote {
  width: 30%;
}
.inputChatBoxMain {
  height: 100px;
}
.inputChatBoxMainInput {
  width: 90% !important;
}
.messagesMainSubb1Subb {
  height: calc(100% - 50px);
}
.messagesMainSubb2Subb1 {
  height: calc(100% - 150px);
}
.messagesMainSubb2Subb1Right {
  /* height: 650px; */
}
.chatBoxClientMain {
  max-width: 94%;
}
.overflowYScroll {
  overflow-y: scroll;
}
.overflowYAuto {
  overflow-y: auto;
}
.messagesMainSubb2 {
  /*height: 750px;*/
}
.messagesMainSubb1SubbScroll {
  height: 100%;
  overflow-y: auto;
}
.messagesMainSubb1SubbScroll1 {
  display: flex;
  align-items: stretch;
}

.overflowParent {
  height: 500px;
}
.OnboardingMentor13ServiceButton2 {
  display: none !important;
}
.navForProfileSwitchBell {
  top: 30px;
  right: 80px;
}
.messagesMain {
  height: calc(100vh - 90px);
}
.messagesMainSubb1 {
  height: 100%;
}
.messagesMainSubb2 {
  height: 100%;
}
.messagesMainSubb2Button {
  display: none;
}
.VideoCallMain {
  height: calc(100vh - 94px);
}
.showChatBack {
  display: block !important;
  width: 30%;
}
.mySmallerVideo {
  width: 320px;
  height: 240px;
}
.mySmallerVideoWrapper {
  bottom: 30px;
  right: 30px;
}
.OnboardingMentor8SetAvailabilitySubbForExcludeDatePickerMain input {
  border: none;
}
.hideNavItemForLargeVP {
  display: none !important;
}
.fakeForWhiteCard1 {
  display: none !important;
}
.fakeView1 {
  display: none !important;
}
.chatLineForVideoCall1 {
  max-width: 94% !important;
}
.fakeForReviewsCard1 {
  display: none !important;
}
.reviewsMain {
  height: calc(100vh - 120px) !important;
}
.reviewsSubb1 {
  width: 836px;
  height: 100%;
}
.reviewsScrollMain {
  height: calc(100% - 83px);
  overflow-y: auto !important;
}
.reviewsScrollSubb {
  height: 100% !important;
}
.fakeForReviewHead2 {
  display: none;
}
.checkForSeclectCategory {
  top: 10px;
  right: 10px;
  width: 20px !important;
  height: 20px !important;
}
.clientNotSelectedCathegoryBackground {
  background-color: rgba(38, 36, 48, 0.1) !important;
}
.sendMessageButtonInSession {
  max-width: 45px;
  max-height: 45px;
}
.ViewRescheduleSessionCardType2Hidden2 {
  max-width: 62px;
  max-height: 90px;
}
.ViewRescheduleSessionCardType2HiddenSub {
  max-width: 62px;
  max-height: 90px;
}
.notesMainPageSubImage {
  max-width: 40px;
  max-height: 40px;
}
.onboardingMentor11ClientSubImage {
  max-width: 62px;
  max-height: 90px;
}
/*GENERAL CSS CLASSES END*/

/*######  TEST #########*/

.mentorsApplicationWrapper {
  max-height: calc(100% - 140px);
}

/* .dataTableHead .MuiTableRow-head .MuiTableCell-head,
.dataTableHead .MuiTableRow-head .MUIDataTableHeadCell-sortActive-58,
.dataTableHead .MuiTableRow-head .MuiTableSortLabel-icon,
.dataTableHead .MuiTableRow-head .MuiButton-label { */
.dataTableHead .MuiTableRow-head .MuiTableCell-head {
  background: #155c6b !important;
  color: white !important;
  font-weight: bold !important;
}

.previewImage {
  font-style: italic;
  text-decoration: underline;
  color: #155c6b;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  font-weight: 600;
}

.companyProfileAdmin {
  height: 800px;
}

.borderLightGray {
  border: 2px solid #f6f8fa;
}
.backColLightBlue {
  background-color: #e0e9ff;
}

.fontSize30 {
  font-size: 30px !important;
}

.userInitialsLarge {
  border-radius: 50%;
  background-color: #155c6b;
  width: 150px;
  height: 150px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.userInitialsLarge p {
  padding: 10px;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 3px;
  color: white;
}

.userInitialsBig {
  border-radius: 50%;
  background-color: #155c6b;
  width: 140px;
  height: 140px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.userInitialsBig p {
  padding: 6px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2.5px;
  color: white;
}

.userInitialsMedium {
  border-radius: 50%;
  background-color: #155c6b;
  width: 50px;
  height: 50px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.userInitialsMedium p {
  /* padding: 6px; */
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: white;
}

.userInitialsSmall {
  border-radius: 50%;
  background-color: #155c6b;
  width: 40px;
  height: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.userInitialsSmall p {
  padding: 6px;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: white;
}

.disabledVideoText {
  position: absolute;
  text-align: center;
  width: 30%;
  height: 30%;
  top: 35%;
  left: 35%;
}

.dateInputAvailability {
  width: 100px !important;
}

.cursorNotAllowed {
  cursor: not-allowed !important;
}

.threeDotsSessionWrapper {
  right: 35%;
  bottom: 150px;
  width: 30%;
  height: 200px;
  /* background-color: #2a7383; */
}

.sessionMessageWrapper {
  /*width: 500px!important;*/
}

.right0 {
  right: 0;
}

.right15 {
  right: 15%;
}

.testBottom {
  bottom: 0;
  left: 0;
}

.mbsc-ios.mbsc-datepicker-inline {
  padding-top: 15px !important;
  border: none !important;
  background-color: #f7f7f7 !important;
  background: #f7f7f7 !important;
  color: #000 !important;
  border-radius: 30px !important;
}

.mbsc-ios.mbsc-datepicker .mbsc-calendar,
.mbsc-ios.mbsc-datepicker .mbsc-calendar-cell,
.mbsc-ios.mbsc-datepicker .mbsc-calendar-slide {
  border-radius: 30px !important;
  background: #f7f7f7 !important;
  color: #000 !important;
  font-weight: 500 !important;
}

.mbsc-calendar-week-days {
  border-radius: 30px !important;
  color: #4d4d4d !important;
}

.xScrollWrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}

.contentWrapper {
  display: flex;
  flex-flow: column;
}
.notificationBottomPopup {
  width: 100%;
  min-height: 50px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: lightgray;
}
footer {
  flex: 0;
  margin-top: auto;
}

.filterMentorPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color:#fff;*/
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}

.grayPopupWrapperBg01 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color:#fff;*/
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

.popUpStart {
  border-radius: 5px;
  padding: 1px 10px;
  position: fixed;
  bottom: 50px;
  left: calc((100vw - 200px) / 2);
  height: 60px;
  background-color: #efefef;
  width: 200px;
  color: #ff6f61;
  font-style: italic;
}

.FilterMentorPopUp {
  width: 1060px;
}
.FilterMentorPopUp1MainSubb {
  width: 920px;
}
.FilterMentorPopUpButtonsMain {
  width: 920px;
}
.FilterMentorPopUp1 {
  width: 500px;
}
.FilterMentorPopUp3 {
  width: 100%;
}
.FilterMentorPopUp4 {
  width: 80%;
  margin: 30px auto;
}
.FilterMentorPopUp2 {
  width: 400px;
}
.multi-range-slider .thumb::before {
  content: "";
  background-color: #155c6b !important;
  position: absolute;
  width: 20px;
  height: 20px;
  border: none !important;
  box-shadow: none !important;
  top: 1px;
}
.multi-range-slider .bar-inner {
  background-color: #155c6b !important;
  border: none !important;
  box-shadow: none !important;
}
.multi-range-slider .bar-left {
  background-color: #e3e3e3 !important;
  border-radius: 10px 0px 0px 10px;
  border: none !important;
  box-shadow: none !important;
  padding: 4px 0px;
}
.multi-range-slider .bar-right {
  background-color: #e3e3e3 !important;
  border-radius: 10px 0px 0px 10px;
  border: none !important;
  box-shadow: none !important;
  padding: 4px 0px;
}
.multi-range-slider {
  display: flex;
  position: relative;
  border: none !important;
  padding: 20px 10px;
  box-shadow: none !important;
}
.multi-range-slider .thumb * {
  background-color: #000 !important;
  border-radius: 4px !important;
  color: #fff;
  box-shadow: none !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.3 !important;
  letter-spacing: 0.75px !important;
  line-height: auto !important;
  width: auto !important;
  height: auto !important;
  padding: 4px 6px !important;
}
.MentorHome2SettingsPanel a {
  text-decoration: none;
}
.profileSmallBoxes {
  width: 420px !important;
}

.serviceWrapper {
  box-shadow: 5px 5px 10px grey;
  height: 250px;
  border-radius: 20px;
}
.heightForBio {
  height: 42px;
  overflow: hidden;
}
.heightForBio2 {
  height: 24px;
  overflow: hidden;
}
.forRecommendedMentors::-webkit-scrollbar {
  height: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}
.forRecommendedMentors::-webkit-scrollbar-thumb {
  background-color: #3e8b9c;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
}
.profileDetailsForm textarea::-webkit-scrollbar {
  width: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  cursor: pointer;
}
.profileDetailsForm textarea::-webkit-scrollbar-thumb {
  background-color: #3e8b9c;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
}
.yearsOfExpTextarea textarea::-webkit-scrollbar {
  width: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  cursor: pointer;
}
.yearsOfExpTextarea textarea::-webkit-scrollbar-thumb {
  background-color: #3e8b9c;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
}
.textareaComponentMain textarea::-webkit-scrollbar {
  width: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  cursor: pointer;
}
.textareaComponentMain textarea::-webkit-scrollbar-thumb {
  background-color: #3e8b9c;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
}
.OnboardingMentor7CreateYourFirstSession textarea::-webkit-scrollbar {
  width: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  cursor: pointer;
}
.OnboardingMentor7CreateYourFirstSession textarea::-webkit-scrollbar-thumb {
  background-color: #3e8b9c;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
}
.checkForRes {
  display: none !important;
}
.OnboardingMentor12WelcomePageSubb3 {
  display: none !important;
}
.logoMain {
  margin-right: 160px !important;
}
.conifrmSessionMessageYourMentorComponentMainText {
  text-align: left !important;
}
.OnboardingMentor12WelcomePageSubb1 {
  min-height: 450px;
}
.HowMentorXWorksImgMainForRes1 {
  display: none !important;
}

/*PERFORMANCE CHART*/

.chartTopWrapper {
  display: flex;
}
.chartBottomWrapper {
  display: flex;
  margin-left: 25px;
  /*justify-content: flex-end;*/
}
.xLabel {
  width: 14%;
}
.xLabel p {
  text-align: center;
}
.yLabelsWrapper {
  padding-top: 15px;
  width: 18px;
}
.yLabel {
  margin-top: 37px;
}
.chartContentWrapper {
  display: flex;
  padding-left: 7px;
  padding-bottom: 10px;
  width: 100%;
}

.chartLineWrapper {
  display: flex;
  align-items: flex-end;
  /*margin-right: 19px;*/
  /*margin-left: 19px;*/
  width: 14%;
  justify-content: center;
}

.chartOneLine {
  width: 20px;
  background-color: #3e8b9c;
  height: 80%;
  border-radius: 10px;
}
.ViewRescheduleSessionCardType2Hidden {
  display: none !important;
}

/*PERFORMANCE CHART END*/

/*CALENDAR CSS START*/

.cePageNotActive {
  color: rgba(200, 200, 200, 1);
}
.calendarIconHover:hover {
  background-color: transparent !important;
}
.calendarIconHover:active {
  background-color: transparent !important;
}

.calendarTest {
  width: 100%;
  margin-top: 50px;
  height: 400px;
}

.calendarLeft {
  float: left;
  padding-left: 50px;
}

.calendarRight {
  width: 60%;
  float: right;
  text-align: center;
}

.calendarRight h1 {
  margin-bottom: 50px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

/*CALENDAR CSS END*/

@media (max-width: 1700px) {
  .selectForStartFormsDiv {
    width: 500px !important;
  }
  .textareaForStartFormsDiv {
    width: 500px !important;
  }
  .OnboardingMentor12WelcomePageSubb {
    width: 80% !important;
  }
  .OnboardingMentor12WelcomePageSubb1 {
    margin-right: 100px !important;
  }
  .OnboardingMentor12WelcomePageSubb2 {
    width: auto !important;
  }
  .onboardingMentor11ClientSingleViewSubb1 {
    width: 80% !important;
  }
  .onboardingMentor11ClientSingleViewSubb2 {
    width: 40% !important;
  }
  .ClientSettingsPanelContactSupportSubb1 {
    width: 70% !important;
  }
}
@media (max-width: 1600px) {
  .yourRatingWrapper,
  .lastSessionsWrapper {
    width: 500px !important;
  }
}
@media (max-width: 1500px) {
  .welcomeImg {
    width: 400px !important;
    height: auto !important;
  }
  .loginStartingPages {
    width: 40% !important;
  }
  .selectForStartFormsDiv {
    width: 400px !important;
  }
  .textareaForStartFormsDiv {
    width: 400px !important;
  }
  .conifrmSessionOrderSummaryMain {
    width: 400px !important;
  }
  .messagesMainSubb2 {
    width: 50% !important;
  }
  .messagesMainSubb1 {
    width: 50% !important;
  }
  .performanceMain {
    width: 90% !important;
  }
  .performanceMain1 {
    display: block !important;
  }
}
@media (max-width: 1400px) {
  .widthAndHightForLandingPageImgMain {
    width: 400px !important;
  }
  .logoMain {
    margin-right: 130px !important;
  }
  .calendarMain1 {
    width: 50% !important;
  }
  .ClientSettingsPanelPersonalInfoSubb1 {
    width: 80% !important;
  }
  .ClientSettingsPanelLoginAndSecuritySubb1 {
    width: 80% !important;
  }
}
@media (max-width: 1305px) {
  .footerMainSubb1 {
    width: 90% !important;
  }
  .FavoriteMentorsSubb {
    width: 90% !important;
  }
}
@media (max-width: 1300px) {
  .OnboardingMentor1 .H2medium {
    font-size: 24px;
  }
  .onboardingMentor1Sub {
    margin: 40px auto !important;
  }
  .MentorProfileUserSideSubb1 {
    width: 90% !important;
  }
  .onboardingMentor11ClientSingleViewSubb4 {
    display: block !important;
  }
  .onboardingMentor11ClientSingleViewSubb2 {
    width: 100% !important;
  }
  .onboardingMentor11ClientSingleViewSubb3 {
    margin-bottom: 20px !important;
  }
  .HowMentorXWorksImgMain {
    width: 400px;
    height: 400px;
  }
  .reviewOnCompletedSessionWrapper {
    width: 540px !important;
  }
  .OnboardingMentor12WelcomePage {
    margin-top: 0px !important;
  }
  .ratingAndSessionsWrapper {
    display: block !important;
  }
  .yourRatingWrapper,
  .lastSessionsWrapper {
    width: 100% !important;
    margin-bottom: 30px;
  }
  .bigScheduleCalendarMain1 {
    position: static !important;
    margin-bottom: 30px !important;
  }
}
@media (max-width: 1200px) {
  .welcomeImg {
    width: 300px !important;
  }
  .onboardingMentor1Sub {
    align-items: stretch !important;
    justify-content: flex-end !important;
  }
  .loginStartingPagesImg {
    width: auto !important;
  }
  .loginStartingPages {
    width: auto !important;
    margin-right: 20px !important;
  }
  .onboardingMentor2Subb {
    display: block !important;
    width: 60% !important;
  }
  .onboardingMentor2Subb2 {
    width: 100% !important;
  }
  .selectForStartFormsDiv {
    width: 320px !important;
  }
  .textareaForStartFormsDiv {
    width: 320px !important;
  }
  .OnboardingMentor8SetAvailabilitySubb {
    width: 700px !important;
  }
  .MentorProfileUserSideSubb1Left {
    width: 40% !important;
  }
  .MentorProfileUserSideSubb1Right {
    width: auto !important;
  }
  .ClientConfirmSessionSubb1 {
    display: block !important;
  }
  .ClientConfirmSessionSubb2 {
    width: 80% !important;
  }
  .ClientConfirmSessionSubb3 {
    width: 80% !important;
    margin-top: 30px !important;
  }
  .conifrmSessionOrderSummaryMain {
    margin-left: 0px !important;
    width: 540px !important;
  }
  .ClientConfirmSessionThankYouMain {
    display: block !important;
  }
  .ClientConfirmSessionThankYouSubb1 {
    width: 80% !important;
  }
  .ClientConfirmSessionThankYouSubb2 {
    width: 80% !important;
  }
  .FilterMentorPopUp {
    width: 960px;
  }
  .FilterMentorPopUp1MainSubb {
    width: 820px;
  }
  .FilterMentorPopUpButtonsMain {
    width: 820px;
  }
  .FilterMentorPopUp1 {
    width: 450px;
  }
  .FilterMentorPopUp2 {
    width: 350px;
  }
  .ClientSettingsPanelContactSupportSubb1 {
    width: 100% !important;
  }
  .footerMainSubb2 {
    margin-right: 100px !important;
  }
  .footerMainSubb3 {
    margin-right: 100px !important;
  }
  .VideoCallImageElement {
    width: 100% !important;
  }
  .VideoCallTextElement {
    display: none !important;
  }
  .showChatBack {
    display: block !important;
    width: 100%;
  }
  .messagesMainSubb2SubbForEditNote {
    width: 100%;
  }
}
@media (max-width: 1060px) {
  .OnboardingMentor12WelcomePageSubb {
    display: block !important;
    width: 400px !important;
    margin: 50px auto 70px auto;
  }
  .OnboardingMentor12WelcomePageSubb1 {
    display: none !important;
  }
  .OnboardingMentor12WelcomePageSubb3 {
    display: block !important;
    width: 400px !important;
    margin-bottom: 30px !important;
  }
  .OnboardingMentor12WelcomePageSubb3 .welcomeImg {
    width: 400px !important;
  }
  .widthAndHightForLandingPageImgMain {
    display: none;
  }
  .buttonTagsMainLanding {
    width: 100% !important;
    margin: 10px 0px !important;
  }
  .headingMainLandingPageTop {
    width: 100% !important;
  }
  .logoMain {
    margin-right: 100px !important;
  }
  .MentorProfileUserSideSubb1 {
    width: 70% !important;
  }
  .MentorProfileUserSideSubb1Subb {
    display: block !important;
  }
  .MentorProfileUserSideSubb1Left {
    width: 100% !important;
  }
  .ClientNotesSubb1 {
    display: block !important;
  }
  .ClientNotesSubb2 {
    margin-top: 20px !important;
  }
  .ClientConfirmSessionSubb2 {
    width: 100% !important;
  }
  .ClientConfirmSessionSubb3 {
    width: 100% !important;
  }
  .communcationOptions {
    margin-bottom: 10px;
  }
  .FilterMentorPopUp {
    width: 90%;
  }
  .FilterMentorPopUp1MainSubb {
    width: 80%;
    display: block !important;
  }
  .FilterMentorPopUpButtonsMain {
    width: 100%;
  }
  .FilterMentorPopUp1 {
    width: 100%;
  }
  .FilterMentorPopUp2 {
    width: 100%;
  }
  .FilterMentorPopUp5 {
    margin: 20px 0px;
  }
  .FilterMentorPopUp .backColElementBackgroundGray {
    background-color: #fff !important;
    border: 2px solid #f7f7f7 !important;
  }
  .calendarMain1 {
    width: 400px !important;
    margin-right: auto !important;
    margin-left: 0px !important;
    margin-bottom: 20px;
  }
  .calendarPopUpMain {
    width: 70% !important;
  }
  .calendarButton {
    bottom: 10px;
  }
  .h1Schedule {
    margin-bottom: 40px !important;
  }
  .right15 {
    right: auto !important;
  }
  .right0 {
    right: auto !important;
  }
  .HowMentorXWorksImgMainForRes1 {
    display: block !important;
  }
  .HowMentorXWorksImgMainForDesk {
    display: none !important;
  }
  .HowMentorXWorksImgMainForRes2 {
    display: block !important;
  }
  .HowMentorXWorksImgMainForRes3 {
    display: block !important;
  }
  .HowMentorXWorksAllText {
    width: 100% !important;
  }
  .HowMentorXWorksImgMain {
    width: 500px !important;
    height: 500px !important;
    margin: 20px auto !important;
  }
  .signupMainSubb1 {
    width: 90% !important;
  }
  .reviewsMain {
    width: 90% !important;
  }
  .reviewsSubb1 {
    width: 90% !important;
  }
  .reviewsMain .ReviewsCardMainCard {
    width: 340px !important;
  }
  .reviewsMain {
    width: 90% !important;
  }
  .reviewsSubb1 {
    width: 90% !important;
  }
  .reviewsMain .ReviewsCardMainCard {
    width: 340px !important;
  }
  .LogInEmailConfirmationSubb1 {
    width: 90% !important;
  }
  .LogInEmailConfirmationSubb2 {
    width: 90% !important;
  }
}
@media (max-width: 991px) {
  .hideNavItemForLargeVP {
    display: block !important;
  }
  .usersDropDownMenu {
    display: none !important;
  }
  .mRight20LargeVP {
    margin-right: 0px !important;
  }
}
@media (max-width: 980px) {
  .loginStartingPagesImg2 {
    display: flex;
    width: 500px;
  }
  .loginStartingPagesImg {
    display: none;
  }
  .welcomeImg {
    width: 100% !important;
  }
  .OnboardingMentor1 .H2medium {
    font-size: 32px;
  }
  .OnboardingMentor4Subb {
    display: block !important;
  }
  .OnboardingMentor4Subb1 {
    margin-bottom: 40px;
  }
  .yearsOfExpTextarea textarea {
    width: 100% !important;
    height: 120px;
  }
  .OnboardingMentor5Subb {
    width: 90% !important;
  }
  .OnboardingMentor5Subb1 {
    width: 100% !important;
  }
  .OnboardingMentor7CreateYourFirstSessionSubb {
    display: block !important;
    width: 80% !important;
  }
  .selectForStartFormsDiv {
    width: 100% !important;
  }
  .textareaForStartFormsDiv {
    width: 100% !important;
  }
  .OnboardingMentor7CreateYourFirstSessionSubb1 {
    width: 100% !important;
    margin-bottom: 40px !important;
  }
  .OnboardingMentor7CreateYourFirstSessionSubb2 {
    width: 100% !important;
  }
  .ClientConfirmSessionThankYouSubb1 {
    width: 100% !important;
  }
  .ClientConfirmSessionThankYouSubb2 {
    width: 100% !important;
  }
  .ClientSettingsPanelLoginAndSecuritySubb1 {
    width: 100% !important;
  }
  .HowMentorXWorksImgMainForRes4 {
    display: block !important;
  }
  .AddServiceComponentP {
    width: 100% !important;
  }
  .LogInSubbMain1 {
    width: 90% !important;
  }
  .LogInSubbMain2 {
    width: 340px !important;
  }
  .formLoginMain {
    width: 340px;
    margin: 0px auto;
  }
  .profileDetailsForm {
    display: block !important;
    width: 90% !important;
  }
  .profileDetailsForm1 {
    width: 90% !important;
  }
  .profileDetailsForm2 {
    width: 90% !important;
  }
  .footerMainSubb2 {
    margin-right: 40px !important;
  }
  .footerMainSubb3 {
    margin-right: 40px !important;
  }
  .mySmallerVideo {
    width: 160px;
    height: 120px;
  }
  .performanceMain {
    width: 100% !important;
    margin: 0px auto 50px !important;
  }
  .performanceMainSubb2 {
    width: 100% !important;
  }
  .chartTopWrapper {
    width: 100% !important;
  }
  .chartContentWrapper {
    width: 100%;
  }
  .chartLineWrapper {
    margin: 0px auto;
  }
  .chartOneLine {
    margin: 0 auto;
  }
  .xLabel p {
    text-align: center;
  }
  .xLabel {
    width: 14.3%;
  }
}
@media (max-width: 900px) {
  .OnboardingMentor8SetAvailabilitySubb1 {
    display: block !important;
  }
  .sessionDurationP {
    text-align: left !important;
  }
  .pencilIcon {
    margin-left: 100px !important;
  }
  .OnboardingMentor8SetAvailabilitySubb {
    display: block !important;
  }
  .ReschedulePopUpSubb {
    width: 600px;
  }
  .ReschedulePopUpSubbDateTime {
    display: block !important;
  }
  .ClientSettingsPanelPersonalInfoSubb1 {
    width: 90% !important;
  }
  .signupMainSubb3 {
    display: block !important;
    width: 340px !important;
    margin: 0px auto;
  }
  .signupMainSubb4 {
    margin-right: 0px !important;
    width: 100% !important;
  }
  .messagesMainSubb1,
  .messagesMainSubb2 {
    width: 100% !important;
  }
  .messagesMainSubb2Button {
    display: block;
  }
  .LogInForgotYourPasswordSubb1 {
    width: 90% !important;
  }
}
@media (max-width: 897px) {
  .reviewsMain .ReviewsCardMainCard {
    width: 500px !important;
  }
}
@media (max-width: 840px) {
  .loginStartingPagesImg2 {
    width: 400px;
  }
  .OnboardingMentor8SetAvailabilitySubb {
    width: 500px !important;
  }
  .onboardingMentor11ClientSingleViewSubb9 {
    width: 100% !important;
  }
  .ClientNotesSubb3 {
    width: 90% !important;
  }
  .LogInClientSetYourPaymentDetailsSubb1 {
    width: 80% !important;
  }
  .MentorHome3NotificationsSubb1 {
    width: 100% !important;
  }
  .footerMainSubb4 {
    display: block !important;
  }
  .footerMainSubb2 {
    width: 100% !important;
    margin: 20px auto !important;
  }
  .footerMainSubb2 h5 {
    width: 100% !important;
    margin: 20px auto !important;
  }
  .footerMainSubb5 {
    width: 500px !important;
    margin: 0px auto !important;
  }
  .formForFooterEmail {
    width: 100% !important;
  }
  .formForFooterEmail input {
    width: 444px !important;
  }
  .footerMainSubb6 {
    width: 100% !important;
    justify-content: space-evenly !important;
    margin-top: 20px !important;
  }
  .buttonForVideoCall {
    width: 35px;
    height: 35px;
  }
  .buttonsForVideoCallSubb {
    width: 180px;
  }
  .buttonsForVideoCall {
    width: 220px;
  }
  .buttonsForAudioCallOnly {
    left: calc((100vw - 220px) / 2);
  }
  .performanceSubbsMain {
    display: block !important;
  }
  .earningsTotNet {
    width: 100% !important;
    margin: 10px auto !important;
  }
  .performanceMainSubb1 {
    width: 90% !important;
    margin: 0 auto !important;
  }
  .earningsHoursClientsSessions {
    width: 100% !important;
    margin: 10px auto !important;
  }
  .earningsHoursClientsSessions p,
  .earningsHoursClientsSessions h3 {
    text-align: center !important;
  }
}
@media (max-width: 740px) {
  .ratingAndSessionsSubWrapper {
    display: block !important;
  }
  .OnboardingMentor2MainSubb {
    width: 90% !important;
  }
  .conifrmSessionOrderSummaryMain {
    width: 100% !important;
  }
  .ClientSettingsPanelContactSupportSubb2 {
    width: 90% !important;
  }
  .ClientSettingsPanelContactSupportSubb3 {
    width: 90% !important;
  }
  .ratingAndSessionsSubWrapperSubb1 {
    display: flex;
    margin-right: 0px;
  }
  .ratingAndSessionsSubWrapperSubb1ImgMain {
    margin-right: 20px;
  }
  .ratingAndSessionsSubWrapperSubb2Text {
    margin: 10px 0px !important;
  }
  .nameRecommendedMentor {
    width: auto;
  }
  .reviewsMain .ReviewsCardMainCard {
    width: calc(100% - 18px) !important;
  }
}
@media (max-width: 700px) {
  .LogInSubbMain2 {
    margin-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .LogInSubbMain1 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .HowMentorXWorksSubWrapper {
    margin-top: 20px !important;
  }
  .MentorHome3NotificationsSubb2 {
    margin-top: 20px !important;
  }
  .OnboardingMentor8SetAvailabilitySubWrapper {
    margin-top: 20px !important;
  }
  .OnboardingMentor13ServiceSubWrapper {
    margin-top: 20px !important;
  }
  .mTopForSmallerCreens {
    margin-top: 20px !important;
  }
  .MentorHome2SettingsPanelSubWrapper {
    margin-top: 20px !important;
  }
  .scheduleSubbMain {
    margin-top: 20px !important;
  }
  .onboardingMentor11ClientSingleViewSubb1 {
    margin-top: 20px !important;
  }
  .MentorHome1SubWrapper {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
  .MentorHome1SubWrapper > h2 {
    margin-bottom: 20px !important;
  }
  .reviewOnCompletedSessionWrapper {
    width: 100% !important;
  }
  .loginStartingPagesImg2 {
    width: 100%;
  }
  .OnboardingMentor1 .H2medium {
    font-size: 24px;
  }
  .listItemMain {
    align-items: stretch !important;
  }
  .listItemMain p {
    width: 90%;
    text-align: left;
  }
  .dotForListItem {
    margin-top: 10px;
  }
  .onboardingMentor1Sub {
    margin: 20px auto !important;
  }
  .OnboardingMentor5Subb {
    margin: 40px auto !important;
  }
  .OnboardingMentor5Subb .H2medium {
    font-size: 24px;
  }
  .OnboardingMentor7CreateYourFirstSessionSubb {
    width: 100% !important;
  }
  .onboardingMentor11ClientSingleViewSubb3 {
    width: 100% !important;
  }
  .onboardingMentor11ClientSingleViewSubb5 {
    width: 90% !important;
  }
  .onboardingMentor11ClientSingleViewSubb5 h4 {
    font-size: 20px !important;
  }
  .onboardingMentor11ClientSingleViewSubb5 h5 {
    font-size: 20px !important;
  }
  .onboardingMentor11ClientSingleViewIcons {
    width: 1.5em !important;
    height: 1.5em !important;
    margin-right: 8px !important;
  }
  .onboardingMentor11ClientSingleViewSubb6 {
    margin-bottom: 30px !important;
  }
  .ReschedulePopUpSubb {
    width: 90%;
    margin-top: 10px !important;
    max-height: calc(100vh - 20px);
    overflow-y: auto;
  }
  .selectForNewNotesPopUpMain {
    display: block !important;
  }
  .selectForNewNotesPopUpMainSubb1 {
    width: 274px;
  }
  .smallSelectCss {
    width: 100% !important;
  }
  .selectForNewNotesPopUpMainSubb2 {
    margin: 20px 0px;
  }
  .calendarPopUpMain {
    width: 90% !important;
  }
  .HowMentorXWorksImgMain {
    width: 400px !important;
    height: 400px !important;
  }
  .ClientSettingsPanelContactSupportSubb4 {
    display: block !important;
  }
  .ClientSettingsPanelContactSupportSubb5 {
    margin-right: 0px !important;
  }
  .ClientSettingsPanelContactSupportSubb5H5 {
    margin-bottom: 10px !important;
  }
  .addServiceComponentSubb1 {
    display: block !important;
  }
  .addServiceComponentSubb1 button {
    margin: 10px 0px !important;
  }
  .addServiceComponentSubb2 {
    display: block !important;
  }
  .addServiceComponentSubb2Dur {
    width: 100px !important;
  }
  .addServiceComponentSubb2AllCom {
    margin-bottom: 10px !important;
  }
  .OnboardingMentor13ServiceButton1 {
    display: none !important;
  }
  .OnboardingMentor13ServiceButton2 {
    display: flex !important;
    margin-right: auto !important;
    margin-left: 0px !important;
  }
  .AddServiceComponentP {
    margin-bottom: 30px !important;
  }
  .ClientSelectCategories h2 {
    font-size: 24px;
    margin-top: 10px !important;
  }
  .ClientSelectCategoriesSubb1 {
    display: block !important;
  }
  .notificationComponentMain {
    display: block !important;
  }
  .NewNotePoUpSub1Wrapper {
    margin-top: 10px !important ;
    margin-bottom: 10px !important;
  }
  .NewNotePoUpSub2Wrapper {
    margin-top: 10px !important ;
    margin-bottom: 10px !important;
  }
  .NewNotePoUpSub3Wrapper {
    margin-top: 10px !important ;
    margin-bottom: 10px !important;
  }
  .NewNotePoUpSub3Wrapper textarea {
    height: 160px !important;
    margin-bottom: 10px !important;
  }
  .selectForNewNotesPopUpMainSubb2 {
    margin: 10px 0px !important;
  }
  .selectForNewNotesPopUpMain {
    margin-bottom: 10px !important;
  }
  .NewNotePoUp button {
    margin-bottom: 10px !important;
  }
  .NewNotePoUp {
    max-height: calc(100vh - 20px);
    overflow-y: auto;
  }
  .availabilityPopUp {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  .notesCardMainSubb {
    width: 100% !important;
    margin-right: 0px !important;
  }
  .onboardingMentor11ClientSingleViewSubb3Subb1 {
    width: 100%;
  }
  .bigScheduleCalendarMain1 {
    width: 100% !important;
  }
}
@media (max-width: 650px) {
  .selectUploadCV {
    width: 100% !important;
  }
  .onboardingMentor2Subb {
    width: 90% !important;
  }
  .OnboardingMentor8SetAvailabilitySubb {
    width: 400px !important;
  }
  .OnboardingMentor8SetAvailabilityMainSubb {
    width: 90% !important;
  }
  .headingMainLandingPageTop .H1medium {
    font-size: 36px;
  }
  .landingHeaders .H2medium {
    font-size: 26px;
  }
  .WhatKindOfMentorSubb {
    width: 90% !important;
    margin: 20px auto !important;
  }
  .buttonTagsClassLanding p {
    font-size: 14px;
  }
  .recommendedMentors {
    width: 260px;
    height: 280px;
  }
  .landingCardsMain .P1medium {
    font-size: 16px !important;
  }
  .landingCardsMain {
    margin-left: 0px !important;
  }
  .forNoteStarMain {
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }
  .forNoteStarMainSubb {
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }
  .viewRescheduleSessionCardMain {
    margin-right: 0px !important;
  }
  .ClientSettingsPanelPersonalInfoSubb1 {
    width: 100% !important;
  }
  .OnboardingMentor8SetAvailabilityMainSubb2 {
    width: 90% !important;
  }
  .landingCardsMainBio {
    width: 260px !important;
  }
  .FavoriteMentorsSubb {
    margin: 20px auto !important;
  }
}
@media (max-width: 600px) {
  .OnboardingMentor7CreateYourFirstSessionMainSubb {
    width: 90% !important;
  }
  .OnboardingMentor7CreateYourFirstSessionMainSubb .H1regular {
    font-size: 32px;
  }
  .OnboardingMentor8SetAvailabilitySubb {
    width: 100% !important;
  }
  .OnboardingMentor8SetAvailability .H1regular {
    font-size: 32px;
  }
  .OnboardingMentor12WelcomePageSubb {
    width: 90% !important;
  }
  .OnboardingMentor12WelcomePageSubb3 {
    width: 90% !important;
  }
  .OnboardingMentor12WelcomePageSubb3 .welcomeImg {
    width: 100% !important;
  }
  .MentorProfileUserSideSubb1 {
    width: 90% !important;
  }
  .onboardingMentor11ClientSingleViewSubb10 {
    width: 100% !important;
  }
  .onboardingMentor11ClientSingleViewSubb11 {
    width: 100% !important;
  }
  .ClientNotesSubb2 {
    display: block !important;
  }
  .ClientNotesSubbButton1 {
    margin-top: 20px;
  }
  .ClientConfirmSessionThankYouButtonsMain {
    display: block !important;
  }
  .MentorHome2SettingsPanelSubb {
    display: block !important;
  }
  .MentorHome2SettingsPanelSubb h6 {
    text-align: left !important;
    margin-top: 10px !important;
  }
  .MentorHome2SettingsPanelSubb1 {
    margin-left: 0px !important;
  }
  .LogInClientSetYourPaymentDetailsSubb1 {
    width: 90% !important;
  }
  .LogInClientSetYourPaymentDetailsSubb2 {
    width: 100% !important;
  }
  .LogInClientSetYourPaymentDetailsSubb2 select {
    width: 100% !important;
    font-size: 14px;
  }
  .LogInClientSetYourPaymentDetailsSubb1 button {
    width: 100% !important;
    padding: 14px 0px !important;
  }
  .scheduleMainSearch {
    width: 100% !important;
  }
  .calendarMain1 {
    width: 100% !important;
  }
  .ClientSettingsPanelPersonalInfoAll {
    display: block !important;
  }
  .ClientSettingsPanelPersonalInfoAll button {
    margin-left: 0px !important;
    margin-right: auto;
    display: flex;
  }
  .ClientSettingsPanelPersonalInfoAll input {
    display: block;
    margin-bottom: 10px;
    width: 100% !important;
  }
  .HowMentorXWorksImgMain {
    width: 100% !important;
    height: auto !important;
  }
  .HowMentorXWorks h2 {
    font-size: 36px;
  }
  .ClientSettingsPanelContactSupportSubb3 {
    display: block !important;
  }
  .LogInEnterNewPasswordWrapperSubb1 {
    width: 90% !important;
  }
  .LogInEnterNewPasswordWrapperSubb2 {
    width: 90% !important;
  }
  .LogInEnterNewPasswordWrapperSubb3 {
    width: 100% !important;
    padding-left: 10px !important;
  }
  .LogInEnterNewPasswordWrapperSubb2 button {
    width: 100% !important;
    padding: 14px 0px !important;
  }
  .LogInEnterNewPassword h2 {
    font-size: 36px;
  }
  .LogInEnterNewPasswordWrapperSubb4 {
    margin-top: 30px !important;
  }
  .profileDetailsForm1 {
    width: 100% !important;
  }
  .profileDetailsForm2 {
    width: 100% !important;
  }
  .profileDetailsForm4 {
    padding-left: 10px !important;
  }
  .footerMainSubb5 {
    width: 100% !important;
  }
  .formForFooterEmail input {
    width: auto !important;
  }
  .formForFooterEmail button {
    margin-left: auto !important;
  }
  .footerMainSubb7 {
    display: block !important;
  }
  .footerMainSubb71 {
    width: 90% !important;
    margin: 0px auto 30px !important;
  }
  .footerMainSubb71p {
    margin-right: auto !important;
  }
  .MentorHome1Subb2 {
    width: 100% !important;
  }
  .ViewRescheduleSessionCardType2Subb1 {
    margin-right: 0px !important;
    width: 100% !important;
  }
  .ViewRescheduleSessionCardType2Hidden {
    display: flex !important;
  }
  .ViewRescheduleSessionCardType2Hidden2 {
    display: none !important;
  }
  .ViewRescheduleSessionCardType2Hidden3 {
    margin-bottom: 8px;
    width: 100%;
  }
  .iconsUpSessions {
    margin-left: 0px !important;
  }
  .performanceMainSubb1Head {
    display: block !important;
  }
  .earningsHoursClientsSessions h3 {
    font-size: 24px;
  }
  .earningsTotNet h4 {
    font-size: 20px;
  }
  .fakeForWhiteCard1 {
    display: flex !important;
  }
  .fakeForWhiteCard2 {
    display: none !important;
  }
  .LogInForgotYourPasswordSubb1 {
    margin-top: 10px !important;
  }
  .LogInForgotYourPasswordSubb2 {
    width: 100% !important;
    padding-left: 10 !important;
  }
  .LogInForgotYourPasswordSubb1 h2 {
    font-size: 36px;
  }
  .LogInForgotYourPasswordSubb1SendMail {
    width: 100% !important;
    padding: 14px 0px !important;
  }
  .FavoriteMentorsSubbHead {
    margin-bottom: 20px !important;
  }
}
@media (max-width: 579px) {
  .cardForSelectCategories {
    margin-right: 0px !important;
  }
  .buttonsForVideoCall {
    left: 20px;
  }
  .buttonsForAudioCallOnly {
    left: calc((100vw - 220px) / 2);
  }
  .mySmallerVideo {
    width: 80px;
    height: 60px;
  }
  .MentorHome1Subb1 {
    display: block !important;
  }
  .MentorHome1Subb1 button {
    margin: 10px 0px !important;
    display: flex;
    justify-content: flex-start;
  }
  .hopeYouHadSatisfactorySub {
    display: block !important;
    width: 100% !important;
  }
  .hopeYouHadSatisfactorySub button {
    margin: 0 auto !important;
  }
  .hopeYouHadSatisfactorySub button:first-child {
    margin-bottom: 20px !important;
  }
  .fakeView1 {
    display: flex !important;
    margin: 0px !important;
    justify-content: flex-start !important;
  }
  .fakeView2 {
    display: none !important;
  }
  .bigScheduleCalendarViewAll {
    width: 100% !important;
  }
  .LogInEmailConfirmationSubb2 h2 {
    font-size: 24px;
  }
  .LogInEmailConfirmationSubb2 button {
    width: 100% !important;
    padding: 14px 0px !important;
  }
  .LogInEmailConfirmation {
    margin: 20px 0px !important;
  }
  .LogInEmailConfirmationSubb1 {
    margin: 20px auto !important;
  }
}

@media (max-width: 542px) {
  .reviewsScrollMain {
    height: calc(100% - 116px);
  }
}

@media (max-width: 500px) {
  .mySmallerVideoWrapper {
    bottom: 110px;
  }
  .buttonsForVideoCall {
    left: calc((100vw - 220px) / 2);
  }
  .OnboardingMentor12WelcomePageSubb .H1medium {
    font-size: 32px !important;
  }
  .OnboardingMentor12WelcomePageSubb .H2regular {
    font-size: 24px !important;
  }
  .OnboardingMentor12WelcomePageSubb .P1medium {
    width: auto !important;
  }
  .OnboardingMentor12WelcomePageSubb button {
    width: 220px !important;
    padding: 10px 0px !important;
  }
  .buttonTagsClassLanding p {
    font-size: 12px;
  }
  .upcomingPastCancelledNavMain {
    width: 260px !important;
  }
  .upcomingPastCancelledNavMain p {
    font-size: 12px !important;
    padding: 10px 10px !important;
  }
  .MentorProfileUserSideSubb1RightSubb {
    width: 100% !important;
  }
  .bigScheduleCalendarMain {
    width: 100% !important;
  }
  .onboardingMentor11ClientSingleViewSubb7 {
    display: block !important;
  }
  .onboardingMentor11ClientSingleViewSubb8 {
    display: block !important;
  }
  .onboardingMentor11ClientSingleViewSubb5 {
    width: 80% !important;
  }
  .onboardingMentor11ClientSingleViewSubb8 button {
    width: 100% !important;
    margin: 10px 0px !important;
  }
  .ReschedulePopUpSubbDateTime2 {
    display: block !important;
  }
  .ReschedulePopUpSubbDateTime3 {
    margin-right: 0px !important;
  }
  .ReschedulePopUp h3 {
    font-size: 20px;
  }
  .conifrmSessionPaymentComponentSelect {
    width: 100% !important;
  }
  .ClientConfirmSessionThankYouSubb1 h1 {
    font-size: 36px;
    text-align: left;
  }
  .checkThankYou {
    width: 3.5em;
    height: 3.5em;
  }
  .onOffSwitchInputOnFilltersMain {
    display: block !important;
  }
  .onOffSwitchInputOnFillters {
    margin-right: auto;
    display: flex;
    margin-bottom: 8px;
  }
  .FilterMentorPopUp h6 {
    font-size: 16px;
  }
  .FilterMentorPopUp h5 {
    font-size: 20px;
  }
  .FilterMentorPopUp label {
    font-size: 16px;
  }
  .MentorHome3Notifications h2 {
    font-size: 36px;
  }
  .LogInSubbMain3 {
    width: 100% !important;
    padding-left: 10px !important;
  }
  .LogInSubbMain2 {
    width: 90% !important;
  }
  .formLoginMain {
    width: 100% !important;
  }
  .formLoginMain button {
    width: 90% !important;
  }
  .signupMainSubb3 {
    width: 90% !important;
  }
  .signupMainSubb4 {
    padding-left: 10px !important;
  }
  .submitMainFormButton {
    width: 90% !important;
    padding: 14px 0px !important;
  }
  .loginStartingPages {
    margin: 50px auto !important;
  }
  .ClientNotesSubb3 h2 {
    font-size: 24px !important;
  }
  .aboutToEndSessionSub,
  .aboutToEndSessionSub2 {
    margin-top: 0px !important;
  }
  .reviewOnCompletedSessionSubWrapper {
    display: block !important;
  }
  .reviewOnCompletedSessionSub2Wrapper {
    width: 40px;
  }
  .onboardingMentor11ClientSingleViewSubb2HeadMain {
    display: block !important;
  }
  .onboardingMentor11ClientSingleViewSubb2HeadMain > div {
    width: 100px;
    margin: 8px 0px !important;
  }
  .conifrmSessionOrderSummarySubbs {
    display: block !important;
  }
  .conifrmSessionOrderSummaryViChAu {
    text-align: left !important;
  }
  .FavoriteMentors h2 {
    font-size: 36px;
    margin-top: 10px !important;
  }
  .FavoriteMentorsSubbHead {
    display: block !important;
  }
}
@media (max-width: 450px) {
  .reviewsScrollMain {
    height: calc(100% - 140px);
  }
  .xLabel {
    transform: rotate(-45deg);
  }
  .checkForRes {
    display: flex !important;
    margin-top: 10px;
  }
  .checkForRes1 {
    display: none !important;
    margin-top: 10px;
  }
  .OnboardingMentor8SetAvailabilitySubb1 {
    margin-left: 10px !important;
  }
  .viewRescheduleSessionCardButtons {
    width: 120px !important;
    font-size: 14px !important;
    padding: 10px 0px !important;
  }
  .viewRescheduleSessionCardMain .P1bold {
    font-size: 16px !important;
  }
  .viewRescheduleSessionCardButtonsMain {
    width: 80% !important;
    margin: 20px auto !important;
  }
  .bigScheduleCalendarMainSubb1 {
    display: block !important;
  }
  .bigScheduleCalendarMainSubb1 h5 {
    text-align: left !important;
    margin: 10px 0px !important;
  }
  .onboardingMentor11ClientSingleViewSubb5 h4 {
    font-size: 16px !important;
  }
  .onboardingMentor11ClientSingleViewSubb5 h5 {
    font-size: 16px !important;
  }
  .ReschedulePopUp .reviewsCardImg2 {
    width: 40px !important;
    height: 40px !important;
  }
  .ReschedulePopUp .P1bold {
    font-size: 16px !important;
  }
  .ClientConfirmSessionSubb2 button {
    width: 100%;
    padding: 10px 0px !important;
    font-size: 20px !important;
    text-align: center !important;
    display: block !important;
  }
  .communcationOptions {
    width: 100%;
    margin-right: 0px !important;
  }
  .ClientSettingsPanelLoginAndSecurityAll {
    display: block !important;
  }
  .ClientSettingsPanelLoginAndSecurityAll button {
    display: flex !important;
    margin-right: auto !important;
    margin-left: 0px !important;
  }
  .ClientSettingsPanelLoginAndSecurity h2 {
    font-size: 36px;
  }
  .ClientSelectCategoriesSubb3 {
    width: 90% !important;
  }
  .OnboardingMentor8SetAvailabilitySubbForExclude {
    display: block !important;
  }
  .MentorHome1 h2 {
    font-size: 36px;
  }
  .headOfMessageCard {
    display: block !important;
  }
  .fakeForReviewsCard1 {
    display: flex !important;
  }
  .fakeForReviewsCard2 {
    display: none !important;
  }
  .reviewsCardMainSubb {
    margin-right: 0px !important;
    width: 100% !important;
  }
  .ReviewsCardMainCard {
    width: 100% !important;
  }
  .fakeForReviewHead1 {
    display: none !important;
  }
  .fakeForReviewHead2 {
    display: block !important;
  }
}
@media (max-width: 400px) {
  .OnboardingMentor7CreateYourFirstSessionMainSubb {
    width: 90% !important;
  }
  .textareaForStartForms {
    height: 160px;
  }
  .buttonTagsClassLanding p {
    font-size: 10px;
  }
  .viewRescheduleSessionCardButtonsMain {
    width: 100% !important;
  }
  .bigScheduleCalendarMainSubb2 {
    display: block !important;
  }
  .bigScheduleCalendarMainSubb2 button {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .selectForNewNotesPopUpMainSubb1 {
    width: 100% !important;
  }
  .ClientNotesSubb4 {
    width: 100% !important;
  }
  .scheduleSubbMain {
    width: 94% !important;
  }
  .MentorHome3NotificationsSubb2 {
    display: block !important;
  }
  .MentorHome3NotificationsSubb2 h2 {
    margin-top: 10px !important;
  }
  .OnboardingMentor13Service h2 {
    font-size: 36px !important;
  }
  .OnboardingMentor8SetAvailabilitySubb1Form {
    display: block !important;
  }
  .OnboardingMentor8SetAvailabilitySubb1FormButton {
    margin: 8px auto !important;
    width: 100% !important;
  }
  .OnboardingMentor8SetAvailabilitySubb1FormInput {
    margin: 8px auto !important;
    width: 100% !important;
  }
  .OnboardingMentor8SetAvailabilitySubb1FormMain {
    margin-right: 0px !important;
  }
  .selectForNewNotesPopUpMain123 {
    margin-right: 0px !important;
  }
  .footerMainSubb71 {
    width: 100% !important;
    display: block !important;
  }
  .dateInputAvailability {
    margin: 8px 0px !important;
  }
  .OnboardingMentor8SetAvailabilitySubbForExcludeDatePicker {
    display: block !important;
  }
  .OnboardingMentor8SetAvailabilitySubbForExcludeDatePickerMain {
    display: block !important;
    margin-right: 0px auto !important;
  }
  .OnboardingMentor8SetAvailabilitySubbForExcludeDatePickerMain input {
    padding: 6px 0px;
    margin: 8px auto;
    display: flex;
    width: 100%;
  }
  .OnboardingMentor8SetAvailabilitySubbForExcludeButton {
    margin-bottom: 8px;
    margin-left: 0px !important;
  }
  .OnboardingMentor8SetAvailabilitySubbForExcludeButtonUpdate {
    padding: 10px 0px !important;
    width: 100% !important;
  }
  .mTopForSmallerCreens h2 {
    font-size: 36px !important;
  }
}
@media (max-width: 350px) {
  .buttonsForVideoCall {
    bottom: 10px;
  }
  .mySmallerVideo {
    width: 72px;
    height: 54px;
  }
  .mySmallerVideoWrapper {
    right: 10px;
    bottom: 90px;
  }
  .bigScheduleCalendarMainSubb1 input {
    width: 100% !important;
    margin: 0px auto !important;
  }
}

@media (max-width: 320px) {
  .buttonsForVideoCall {
    left: calc((100vw - 220px) / 2);
  }
  .OnboardingMentor8SetAvailabilitySubWrapper {
    display: block !important;
  }
  .OnboardingMentor8SetAvailabilitySubWrapper h2 {
    margin-top: 10px !important;
  }
}
@media (max-width: 306px) {
  .reviewsScrollMain {
    height: calc(100% - 173px);
  }
}

@media (max-width: 300px) {
  .mySmallerVideoWrapper {
    display: none !important;
  }
}
